import React from 'react';
import { Link } from 'gatsby';
import { ComponentWrapper } from './LittleBuddy.css';

const LittleBuddy = () => {
	return (
    <ComponentWrapper className={`c-LittleBuddy`}>
      
      <Link to={"/"}>Home</Link>
      <Link to={"/news/"}>News</Link>
      <Link to={"/vegas"}>Founder's Series</Link>
    </ComponentWrapper>
	); 
};

export default LittleBuddy;