import React from 'react';
import { DataContext } from './createContext';

const withContext = (WrappedComponent) => {
	const WithHOC = (props) => {
    return (
      <DataContext.Consumer>
        {context => 
					<WrappedComponent {...props} context={context} />
        }
      </DataContext.Consumer>
    )
	}
	WithHOC.WrappedComponent = WrappedComponent;

	return WithHOC;
}

export default withContext;