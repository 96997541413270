import React from 'react'
import { Helmet } from 'react-helmet'
import './src/global.css'

import AppProvider from './src/store/provider'
import Menu from 'components/Global/Menu'
import LittleBuddy from './src/components/Global/LittleBuddy'
import Footer from 'components/Global/Footer'
// import { LocaleProvider, LocaleContext } from './src/store/localeProvider';
// import CookiePolicy from './src/components/Global/CookiePolicy/CookiePolicy';

const MetaInformation = props => {
  const { title, description, image, url, twitter } = props
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />
      <meta name="twitter:card" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content={twitter} />
      <meta name="twitter:image:src" content={image} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={image} />
      <meta name="og:url" content={url} />
      <meta name="og:site_name" content={title} />
      <meta name="og:type" content="website" />
      <link
        rel="shortcut icon"
        sizes="32x32"
        href="https://webcdn.hirezstudios.com/smite2/base/favicon.png"
      />
    </Helmet>
  )
}

export const wrapRootElement = ({ element }) => {
  return (
    <div className="root">
      <MetaInformation title="SMITE Esports" />
      <AppProvider>{element}</AppProvider>
    </div>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <div className="page">
      <div className="header-container">
        <Menu {...props} />
        <LittleBuddy />
      </div>
      {element}
      <Footer />
    </div>
  )
}
