import styled from 'styled-components';

export const ComponentWrapper = styled.div`
width: 100%;
  section.footer {
  background: rgba(42,42,42,1);
  border-top: .1rem solid var(--text);
   &>div {
     align-items: center;
     display: flex;
     padding: 0 12.6rem;
   }
}

section.footer {
  .top {
    border-bottom: .1rem solid var(--border);
    min-height: 8rem;
    @media screen and (max-width: 799px) {
      flex-direction: column;
      padding-bottom: 2rem;
      padding-top: 2rem;
    }
    &__logo {
      margin-right: 2rem;
      width: 10rem;
      @media screen and (max-width: 799px) {
        margin-right: initial;
      }
    }
    &__social {
      display: flex;
      justify-content: center;
      @media screen and (max-width: 799px) {
        padding: 2rem 0;
      }
      a {
        align-items: center;
        border-radius: 2rem;
        display: flex;
        height: 3.8rem;
        justify-content: center;
        text-decoration: none;
        transition: background .2s ease-in-out;
        width: 3.8rem;
        .icon {
          color: white;
          font-size: 4rem;
        }
        &:hover {
          .icon {
            color: var(--primary);
          }
        }
        &:not(:last-of-type) {
          margin-right: 1.6rem;
        }
      }
    }
    &__go-up {
      margin-left: auto;
      @media screen and (max-width: 799px) {
        margin-left: initial;
      }
      a {
        align-items: center;
        color: white;
        display: flex;
        font-size: 1.6rem;
        font-weight: bold;
        text-decoration: none;
      }
      i {
        align-items: center;
        border: .3rem solid white;
        border-radius: 50%;
        display: flex;
        font-size: 1.1rem;
        justify-content: center;
        margin-left: 1.4rem;
        padding: 0.5rem;
        transition: transform .2s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.2);
        }
      }
    }
  }
  .middle {
    &__links {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-right: auto;
      max-height: 18rem;
      a {
        color: rgba(42, 42, 42, 1);
        font-size: 1.6rem;
        line-height: 4.4rem;
        margin-right: 4rem;
        text-decoration: none;
        text-transform: capitalize;
        transition: color .2s ease-in-out;
        &:hover {
          color: var(--primary);
        }
      }
    }
    &__ratings {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .pegi {
      margin-right: 2rem;
    }
    .esrb {
        align-items: flex-start;
        color: white;
        display: flex;
        justify-content: center;
        position: relative;
      &__details {
        margin: 0 0 0 1rem;
        padding: 0;
        li {
          font-size: 1rem;
          line-height: 2rem;
          list-style: none;
          &:after {
            display: none;
          }
        }
      }
      &__online {
        bottom: -3.2rem;
        font-size: 1rem;
        left: 0;
        position: absolute;
      }
    }
  }

  .bottom {
    background-color: white;
    min-height: 7.8rem;
    @media only screen and (max-width: 1199px) {
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      padding: 2rem;
    }
    .hirez {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      &__logo {
        margin-right: 3rem;
        width: 12.5rem;
        img {
          height: auto;
          width: 100%;
        }
        @media screen and (max-width: 799px) {
          width: 6rem;
        }
      }
      &__links {
        display: flex;
        flex-wrap: wrap;
        margin-right: auto;
        a {
          color: rgba(42, 42, 42, 1);
          font-size: 1.4rem;
          text-decoration: none;
          white-space: nowrap;
          &:hover {
            text-decoration: underline;
          }
          &:not(:last-of-type) {
            &:after {
              content: "|";
              display: inline-block;
              padding: 0 1.2rem;
            }
          }
        }
      }
    }
    .ga-film img {
      width: 4rem;
      margin-left: 2rem;
    }
    .bottom__copyright {
      color: rgba(42, 42, 42, 1);
      font-size: 1.4rem;
      margin-left: auto;
      padding-left: 1rem;
      text-transform: none;
      @media screen and (max-width: 1199px) {
        margin-left: initial;
      }
      @media screen and (max-width: 799px) {
        font-size: 1rem;
      }
    }
  }
`;