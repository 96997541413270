import React, { Component } from 'react'
import { render } from 'react-dom'
import { Link } from 'gatsby'
import { ComponentWrapper } from './Footer.css'

var smoothScroll = {
  timer: null,

  stop: function() {
    clearTimeout(this.timer)
  },

  scrollTo: function(id, callback) {
    var settings = {
      duration: 1000,
      easing: {
        outQuint: function(x, t, b, c, d) {
          return c * ((t = t / d - 1) * t * t * t * t + 1) + b
        },
      },
    }
    var percentage
    var startTime
    var node = document.getElementById('root')
    var nodeTop = node.offsetTop
    var nodeHeight = node.offsetHeight
    var body = document.body
    var html = document.documentElement
    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    var windowHeight = window.innerHeight
    var offset = window.pageYOffset
    var delta = nodeTop - offset
    var bottomScrollableY = height - windowHeight
    var targetY =
      bottomScrollableY < delta
        ? bottomScrollableY - (height - nodeTop - nodeHeight + offset)
        : delta

    startTime = Date.now()
    percentage = 0

    if (this.timer) {
      clearInterval(this.timer)
    }

    function step() {
      var yScroll
      var elapsed = Date.now() - startTime

      if (elapsed > settings.duration) {
        clearTimeout(this.timer)
      }

      percentage = elapsed / settings.duration

      if (percentage > 1) {
        clearTimeout(this.timer)

        if (callback) {
          callback()
        }
      } else {
        yScroll = settings.easing.outQuint(
          0,
          elapsed,
          offset,
          targetY,
          settings.duration
        )
        window.scrollTo(0, yScroll)
        this.timer = setTimeout(step, 10)
      }
    }

    this.timer = setTimeout(step, 10)
  },
}

var smoothScroll = {
  timer: null,

  stop: function() {
    clearTimeout(this.timer)
  },

  scrollTo: function(id, callback) {
    var settings = {
      duration: 1000,
      easing: {
        outQuint: function(x, t, b, c, d) {
          return c * ((t = t / d - 1) * t * t * t * t + 1) + b
        },
      },
    }
    var percentage
    var startTime
    var node = document.getElementById('root')
    var nodeTop = node.offsetTop
    var nodeHeight = node.offsetHeight
    var body = document.body
    var html = document.documentElement
    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    var windowHeight = window.innerHeight
    var offset = window.pageYOffset
    var delta = nodeTop - offset
    var bottomScrollableY = height - windowHeight
    var targetY =
      bottomScrollableY < delta
        ? bottomScrollableY - (height - nodeTop - nodeHeight + offset)
        : delta

    startTime = Date.now()
    percentage = 0

    if (this.timer) {
      clearInterval(this.timer)
    }

    function step() {
      var yScroll
      var elapsed = Date.now() - startTime

      if (elapsed > settings.duration) {
        clearTimeout(this.timer)
      }

      percentage = elapsed / settings.duration

      if (percentage > 1) {
        clearTimeout(this.timer)

        if (callback) {
          callback()
        }
      } else {
        yScroll = settings.easing.outQuint(
          0,
          elapsed,
          offset,
          targetY,
          settings.duration
        )
        window.scrollTo(0, yScroll)
        this.timer = setTimeout(step, 10)
      }
    }

    this.timer = setTimeout(step, 10)
  },
}

class Footer extends Component {
  handleClick = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  render() {
    const { t } = this.props

    return (
      <ComponentWrapper className="c-footer">
        <section className="footer">
          <div className="top">
            <div className="top__logo">
              <Link to="/">
                <svg
                  version="1.1"
                  id="Layer_1"
                  className="text-bold-gold footer-logo"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 670.3 318.3"
                >
                  <g>
                    <g>
                      <polyline
                        fill="currentColor"
                        points="94.3,183.6 94.3,159 140.9,159 140.9,153.6 94.3,153.6 94.3,100 170.3,100 170.3,124.2 124,124.2  124,129.9 170.3,129.9 170.3,183.6 94.3,183.6   "
                      ></polyline>
                      <polygon
                        fill="currentColor"
                        points="178.1,183.6 178.1,100 201,100 228.1,129.6 254.4,100 277.4,100 277.4,183.6 248.3,183.6  248.3,143.3 227.7,166.8 207.5,143.3 207.5,183.6   "
                      ></polygon>
                      <rect
                        x="285.2"
                        y="100"
                        fill="currentColor"
                        width="28.7"
                        height="83.6"
                      ></rect>
                      <polygon
                        fill="currentColor"
                        points="321.7,100 397,100 397,124.5 374,124.5 374,183.6 345.3,183.6 345.3,123.5 321.7,123.5   "
                      ></polygon>
                      <polygon
                        fill="currentColor"
                        points="404.8,183.6 404.8,100 469.4,100 462.8,122.5 433,122.5 433,130.1 460.5,130.1 454.1,151.7  433,151.7 433,158.6 475.1,158.6 467.4,183.6   "
                      ></polygon>
                      <polygon
                        fill="currentColor"
                        points="511.2,100 498.5,124.6 547.5,124.6 547.5,129.8 516.8,129.8 482.8,183.6 576.1,183.6 576.1,158.4  532.4,158.4 535.4,152.8 576.1,152.8 576.1,100   "
                      ></polygon>
                    </g>
                    <g id="XMLID_00000178196066452856471920000005870034792829983877_">
                      <g>
                        <path
                          fill="currentColor"
                          d="M605.2,70.8v187H439.3l12.7-45.1H65.2V70.8h405.6l3.2-10.4h65.9l-5.7,10.4H605.2z M598.2,205.8v-128h-75.5 l5.4-10.4h-49l-3.2,10.4H72.1v128h389l-7.4,23.9l20.3-23.9H598.2z M593.3,244.2L583,219.5h-5.8l-9.8,24.7h6.3l1.4-3.6h10.4 l1.5,3.6H593.3z M563.6,244.2v-24.7h-5.8v9.2h-10.9v-9.2h-5.8v24.7h5.8v-9.9h10.9v9.9H563.6z M526.4,236c6.4,0,9.6-2.8,9.6-8.4 c0-5.4-3.4-8-10.3-8h-6.8v24.7h5.8V236H526.4z M514.2,244.2v-5.6h-10.6v-19.1h-5.8v24.7H514.2z M494,244.2l-10.3-24.7h-5.7 l-9.8,24.7h6.3l1.4-3.6h10.4l1.5,3.6H494z"
                        ></path>
                        <polygon
                          fill="currentColor"
                          points="580.2,227.8 583.2,235 577.3,235    "
                        ></polygon>
                        <path
                          fill="currentColor"
                          d="M529.8,227.5c0,1.9-1.1,2.9-3.5,2.9h-1.7v-5.3h1C528.4,225.1,529.8,225.9,529.8,227.5z"
                        ></path>
                        <polygon
                          fill="currentColor"
                          points="480.8,227.8 483.8,235 477.9,235    "
                        ></polygon>
                      </g>
                    </g>
                  </g>
                </svg>
              </Link>
            </div>
            <div className="top__social">
              <a target="_blank" href="https://twitter.com/SmitePro">
                <i className="icon icon-x"></i>
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/officialsmitepro"
              >
                <i className="icon icon-facebook"></i>
              </a>
              <a target="_blank" href="https://www.youtube.com/Smitepro">
                <i className="icon icon-youtube"></i>
              </a>
              <a target="_blank" href="https://www.twitch.tv/smitegame">
                <i className="icon icon-twitch"></i>
              </a>
            </div>

            <div className="top__go-up">
              <a onClick={this.handleClick}>
                Back To Top<i className="icon-keyboard_arrow_up">▲</i>
              </a>
            </div>
          </div>
          <div className="middle">
            <div className="middle__links"></div>
          </div>
          <div className="bottom">
            <div className="hirez">
              <div className="hirez__logo">
                <a href="https://www.hirezstudios.com/" target="_blank">
                  <img src="https://webcdn.hirezstudios.com/smite/v3/img/hirez-logo.png" />
                </a>
              </div>
              <div className="hirez__links">
                <a href="https://www.hirezstudios.com/games/" target="_blank">
                  Games
                </a>
                <a href="https://www.hirezstudios.com/careers" target="_blank">
                  Careers
                </a>
                <a href="https://www.hirezstudios.com/press" target="_blank">
                  Press
                </a>
                <a
                  href="https://webcdn.hirezstudios.com/hirez-studios/legal/PaladinsStrikeEndUserAgreement-(2017-08-22).pdf"
                  target="_blank"
                >
                  EULA
                </a>
                <a href="https://www.hirezstudios.com/privacy">
                  Privacy Policy
                </a>
                <a className="cky-banner-element">
                  Manage Personal Information
                </a>
              </div>
            </div>
            <div className="bottom__copyright">
              &copy; {new Date().getFullYear()} Hi-Rez Studios, Inc. All Rights
              Reserved. SMITE is a registered trademark of Hi-Rez Studios, Inc.
              All RIghts Reserved.
            </div>
            <div className="ga-film">
              <a href="https://Georgia.org/film" target="_blank">
                <img
                  src="https://webcdn.hirezstudios.com/studios/georgia-peach.png"
                  alt="Georgia Film"
                />
              </a>
            </div>
          </div>
        </section>
      </ComponentWrapper>
    )
  }
}

export default Footer
