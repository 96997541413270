import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background: var(--background);
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.1);
  display: none;
  justify-content: space-between;
  margin-top: 3.3rem;
  width: 100%;
  @media only screen and (max-width: 920px) {
    display: flex;
    order: 3;
  }
  a {
    color: var(--text);
    flex: 1;
    font-size: 1.2rem;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border-right: 1px solid var(--border);
    &:hover {
      border-bottom: 5px solid var(--primary);
    }
  }
`;